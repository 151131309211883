
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}
body{
  background: black;
}
h1, h4, p, a{
color: white;
text-decoration: none;
}
ul{
  list-style-type: none;
}
.btn{
padding: 10px 20px;
font-size: 1rem;
text-transform: uppercase;
background: rgb(248, 217, 15);
color: #222;
border: 1px solid white;
font-weight: 600;
cursor: pointer;
}
.btn-light{
  background: transparent;
  color: white;
}
.btn:hover{
  background: rgba(255,255,255, 0,2);
  color: white;
  transition: 0.3s;
}
