.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background: black;
}
.card-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

}
.card{
    border: 1px solid white;
    color: white;
    text-align: center;
    padding: 3rem;
}
.card:hover{
    background-color: #141414;
}
.card h3{
    font-size: 1.4rem;
    padding: 1rem;
}
.card p{
    padding: 10px 0;
}
.bar{
    border-bottom: 1px solid white;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}
.btc{
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem 0;
}
.button{
    text-transform: uppercase;
    background: rgb(248, 217, 15);
    color: #222;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    top: 20px;
    position: relative;
    top: 14px;
    
}

@media screen and (max-width:640px){
    .card{
        padding: 2rem;
    }
    .card-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
    .btc{
        font-size: 3rem;
        font-weight: 500;
        padding: 1rem 0;
    }
    .button{
       width: 100%;
       font-size: 10px;
       font-weight: bold;
    }

}